import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QueryState, queryAdapter } from './query.state';

export const QUERY_STATE_NAME = 'query';
const selectQueryState = createFeatureSelector<QueryState>(QUERY_STATE_NAME);
export const querySelector = queryAdapter.getSelectors();
export const selectQuery = createSelector(
  selectQueryState,
  querySelector.selectAll
);
export const selectQueryEntities = createSelector(
  selectQueryState,
  querySelector.selectEntities
);
export const selectTotalQuery = createSelector(
  selectQueryState,
  state => state.total
);
export const selectPagination = createSelector(
  selectQueryState,
  state => state.pagination
);
export const selectFilterAndPagination = createSelector(
  selectQueryState,
  state => ({ filter: state.filter, pagination: state.pagination })
);

export const selectIntentIds = createSelector(
  selectQueryState,
  state => state.intentIds
);
