import { paginationConfig } from '@constants';
import { Pagination, Query, SelectModel } from '@models';
import { QueryPageModel } from '@models/pages';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export interface QueryState extends EntityState<Query> {
  pagination: Pagination;
  total: number;
  filter: QueryPageModel.filter;
  intentIds: SelectModel.Option[];
}
export const queryAdapter = createEntityAdapter<Query>();
export const queryInitialState: QueryState = queryAdapter.getInitialState({
  pagination: {
    page: 1,
    offset: paginationConfig.defaultSize,
  },
  total: 0,
  intentIds: [],
  filter: {},
});
