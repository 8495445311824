import { ApiResponse, Pagination, Query } from '@models';
import { QueryPageModel } from '@models/pages';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const queryActions = createActionGroup({
  source: '[Query page]',
  events: {
    fetchQuery: emptyProps(),
    'fetch Query Success': props<{ data: ApiResponse<Query[]> }>(),
    updateFilter: props<{ data: QueryPageModel.filter }>(),
    updateFilterSuccess: props<{ data: QueryPageModel.filter }>(),
    updatePagination: props<{ data: Pagination }>(),
    exportToCsv: emptyProps(),
    updateLabel: emptyProps(),
    'fetch intentIds': emptyProps(),
    'fetch intentIds success': props<{ data: string[] }>(),
  },
});
